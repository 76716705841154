import "./App.css"
import { me, metaMe } from "./Me";
import { NumberProperty, StringArray, StringProperty, Structure } from "./components/structures"

export default function App() {
	return <Obgekt object={me} last/>
}

function Obgekt(props) {
	const {
		object,
		name,
		last,
		...rest
	} = props
	const properties = Object.entries(object)
	const getMeta = typeof (object.getMeta) === "function" ? object.getMeta : () => metaMe.default;
	const lastPropertyName = properties.at(-1)[0]

	return (<Structure name={name} open="{" close="}" last={last} {...rest}>
		{properties.map(([ key, value ]) => {
			const isLast = key === lastPropertyName

			switch (typeof (value)) {
				case "string":
					return <StringProperty name={key} meta={getMeta(key)} value={value} last={isLast}/>
				case "number":
					return <NumberProperty
						className="alt-value" name={key} meta={getMeta(key)} last={isLast}/>
				case "object":
					if (Array.isArray(value)) {
						return <StringArray name={key} meta={getMeta(key)} last={isLast}/>
					}
					return <Obgekt name={key} object={value} last={isLast}/>
				default:
					return null
			}
		})}
	</Structure>)
}
