import "./style.css"

export function Structure({ name, open, close, children, last, ...rest }) {
	return (<div {...rest}>
		{name && <span className="property-name">{`${name}: `}</span>}
		<Syntax syntax={open}/>
		<div className="plus-one">{children}</div>
		<Syntax syntax={close}/>
		{!last && <Comma/>}
	</div>);
}

export function StringArray({ meta, ...rest }) {
	const lastValue = meta.at(-1)
	return (<Structure open="[" close="]" {...rest}>
		{meta.map(metaItem => (
			<StringProperty
			 	key={metaItem.value}
				meta={metaItem}
				className="value"
				last={lastValue === metaItem}
			>{`"${metaItem.value}"`}</StringProperty>
		))}
	</Structure>);
}

export function Property({ name, meta, children, last, ...rest }) {
	return (<div {...rest}>
		{name && <span className="property-name">{`${name}: `}</span>}
		<span className="value">{children}</span>
		<span>{!last && <Comma/>}</span>
		<> {meta.icon}</>
	</div>)
}

export function NumberProperty({ name, meta, ...rest }) {
	return <Property name={name} meta={meta} {...rest}>{meta.value}</Property>;
}

export function StringProperty({ name, meta, ...rest }) {
	if (meta.url?.startsWith("https")) {
		return <Property name={name} meta={meta} {...rest}>
			<a href={meta.url}>{`"${meta.value}"`}</a>
		</Property>
	}
	return <Property name={name} meta={meta} {...rest}>{`"${meta.value}"`}</Property>;
}

export function Syntax({ syntax, customStyle, ...rest }) {
	return <span className="syntax" {...rest}>{syntax}</span>
}

export function Comma({ ...rest }) {
	return <Syntax className="comma" syntax={","} {...rest}/>;
}

export function EssVeeGee({ children, ...rest }) {
	const svgStyle = {
		height: "calc(5vw)",
		width: "calc(10vw)",
		maxHeight: "40px",
		maxWidth: "40px",
		verticalAlign: "middle"
	};
	return <svg style={svgStyle} {...rest}>{children}</svg>
}
